import React from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

const Work = () => {
    return (
        <>
            <div className="container">
                <div className="tokyo_tm_portfolio">
                    <div className="tokyo_tm_title">
                        <div className="title_flex">
                            <div className="left">
                                <span>A Few Things I Have Been Working On as a software developer</span>
                                <h3>Working on</h3>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio_filter">
                        <Tabs>
                            <TabList>
                                <Tab>Web3 / Blockchain</Tab>
                                <Tab>Java springboot</Tab>
                            </TabList>
                            <div className="list_wrapper">
                                <TabPanel>
                                    <div className="main_details">
                                        <div className="textbox">
                                            <p>
                                                I work as a coordinator for the minor in Blockchain at the Amsterdam
                                                University of Applied Sciences,
                                                where I also teach the development course.
                                                Our aim is to offer our students a comprehensive learning experience,
                                                enabling them to experiment,
                                                design and construct blockchain-based applications for real-world
                                                clients.<br/>
                                            </p>
                                            <p>
                                                One of the highlights of our program is the successful creation of an
                                                experiment, a proof-of-concept of a functional blockchain prototype.
                                                Our past editions have seen our students work on a range of exciting use
                                                cases, including Fintech, Logistics, Energy, Arts, and Fashion.
                                            </p><br/>
                                            <p>
                                                As part of my role, I provide engaging and informative lessons on
                                                Blockchain development, where students learn to create smart contracts
                                                for either the Ethereum or Cardano blockchain.
                                                By using web3 and tools such as Metamask, our students can develop fully
                                                functional decentralized applications.
                                                In our most recent assignment, our students were tasked with creating a
                                                car sales and mileage registration app using Blockchain technology. We
                                                are excited to see the innovative and creative solutions our students
                                                will come up with, and we look forward to witnessing their growth as the
                                                next generation of blockchain experts.
                                            </p>
                                            <br></br>
                                            <p>Please review (a part of) the content for the <a className="d"
                                                                                                href={`https://github.com/pieterleek/blockchainDevelopmentETH`}
                                                                                                target="_blank"
                                                                                                rel="noreferrer">Ethereum
                                                Developer Course</a> here </p>
                                            <p>Please review (a part of) the content for the <a className="d"
                                                                                                href={`https://github.com/pieterleek/blockchainDevelopmentADA`}
                                                                                                target="_blank"
                                                                                                rel="noreferrer">Cardano
                                                Developer Course</a> here </p>
                                            <p>Please review (a part of) the content for the <a className="d"
                                                                                                href={`https://github.com/pieterleek/blockchainArchitecture`}
                                                                                                target="_blank"
                                                                                                rel="noreferrer">Blockchain
                                                architecture Course</a> here. (With Hyperledger Fabric) </p>
                                            </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>

                                    <p>Check my <a className="d" href={`https://github.com/pieterleek`} target="_blank"
                                                   rel="noreferrer">Github account</a></p>
                                    <br></br>
                                    <p>Please review a (part of) a <a className="d"
                                                                      href={`https://github.com/pieterleek/FestivalMuntjes-backend/tree/master/src/main/java/io/qman/festivalcoins`}
                                                                      target="_blank"
                                                                      rel="noreferrer">sprint boot application I'm
                                        working on (With JWT and Postgres DB). Deployed on AWS EC2 </a></p>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Work;

