import React from "react";
import Social from "./Social";

const Home = () => {
    return (<>
        <div className="tokyo_tm_home">
            <div className="home_content">
                <div className="avatar">
                    <div
                        className="image avatar_img"
                        style={{
                            backgroundImage: "url(assets/img/slider/1.png)",
                        }}
                    ></div>
                </div>
                <div className="details">
                    <h3 className="name">Welcome</h3>
                    <p className="job">
                        Allow me to introduce myself: my name is Pieter Leek. <br />I am a experienced and highly skilled software developer, IT trainer and educator
                        My professional joy comes from teaching and crafting innovative software solutions.
                        I possess a natural curiosity and skill for solving complex problems and relish the opportunity to build or repair IT solutions.
                    </p>
                    <p><script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="pieterleek" data-color="#FFDD00" data-emoji=""  data-font="Cookie" data-text="Buy me a coffee" data-outline-color="#000000" data-font-color="#000000" data-coffee-color="#ffffff" ></script></p>
                    <Social/>
                </div>
            </div>
        </div>
    </>);
};

export default Home;
