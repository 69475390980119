import React from "react";

const Intro = () => {
    const introContent = {
        image: "assets/img/slider/PieterLeek.png",
        name: "Pieter Leek",
        designation: "Backend software developer, Technical trainer, Teacher",
        text: (
            <>
                <p>
                    With over 25 years of experience in IT and over 12 years in teaching, mentoring, and coaching
                    students in a variety of academic settings,
                    I have a passion for teaching that inspires my students to learn and grow, not just in the field of
                    IT, but also as individuals.
                    I take pride in connecting with students from diverse backgrounds and bringing out the best in each
                    of them.
                </p>
                <p>
                    As a seasoned software developer, I've had the privilege of contributing to a diverse array of
                    projects, ranging from small-scale applications to large enterprise-level software systems. My
                    robust understanding of software development principles, encompassing object-oriented programming,
                    data structures, algorithms, and software testing, positions me to consistently deliver high-quality
                    solutions to clients.

                    My enthusiasm for IT has been a constant driving force throughout my professional journey. From a
                    young age, I found myself captivated by the internet and its boundless possibilities. Remarkably, I
                    was an early adopter in the Netherlands, establishing an internet connection in 1991, even before
                    the commercial emergence of the World Wide Web.

                    In 1993, I conducted my initial training session on website building. Regrettably, the reception was
                    lukewarm, as the internet and the World Wide Web were then unfamiliar concepts and didn't seem
                    particularly valuable in the daily lives of the students. How perceptions have changed since then
                    :).
                </p>
                <p>
                    My curiosity for technology has driven me to become an expert in various areas of ICT, including web
                    development, network management, and security. With over 25 years of experience, I have developed a
                    deep understanding of the intricacies of the digital world and have acquired the necessary skills to
                    overcome its challenges.
                    I am committed to staying up-to-date with the latest trends and technologies in software development
                    and keep my knowledge and skills current.
                    With proficiency in various programming languages, including Java, Python, JavaScript, and
                    TypeScript, I am capable of working with various software development tools and platforms.
                </p>
                <p>
                    Friends, family, and colleagues describe me as a highly versatile individual with a broad range of
                    competencies and talents.
                    When I'm not teaching or developing software, I enjoy spending my time reading, traveling, DIY,
                    camping with my Land Rover Defender, and sailing with my converted lifesaving boat.
                    I also enjoy spending time with my family and friends.
                    If you have any questions or would like to learn more about my teaching and software development
                    experience, please feel free to contact me. I look forward to hearing from you!
                </p>

            </>
        ),
    };

    return (
        <>
            <div className="top_author_image">
                <img src={introContent.image} alt="about"/>
            </div>
            <div className="about_title">
                <h3>{introContent.name}</h3>
                <span>{introContent.designation}</span>
            </div>
            <div className="about_text">{introContent.text}</div>
        </>
    );
};

export default Intro;
