import React from "react";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
    return (
        <div className="container">
            <div className="tokyo_tm_contact">
                <div className="tokyo_tm_title">
                    <div className="title_flex">
                        <div className="left">
                            <span>Contact</span>
                            <h3>Get in Touch</h3>
                        </div>
                    </div>
                </div>
                <>
                    <p>
                        Please contact me via <a href={"https://www.linkedin.com/in/pieterleek/"}>Linkedin</a> or email (pieter@qman.io)
                    </p>
                    <p>Make a appointment via <a href={"https://calendly.com/pieter-leek/30min"}>Calendly</a></p>
                </>
                {/* END TITLE */}
            </div>
        </div>
    );
};

export default Contact;
