import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {FaMoon, FaSun} from "react-icons/fa";
import {Link} from "react-router-dom";
import Home from "../components/Home";
import About from "../components/about/AboutMain";
import Contact from "../components/Contact";
import CopyRight from "../components/CopyRight";
import PageTitle from "../components/PageTitle";
import Work from "../components/Work";
/**/

const Website = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);

    useEffect(() => {
        document.body.classList.toggle("dark", isDarkMode);
        localStorage.setItem("isDarkModeEnabled", isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <>
            <PageTitle title="Website Qman.io - Pieter Leek"/>
            {/* End page title for seo */}

            <button className="theme-switcher-label" onClick={toggleDarkMode}>
                {isDarkMode ? (
                    <>
                        <FaSun/>
                    </>
                ) : (
                    <>
                        <FaMoon/>
                    </>
                )}
            </button>
            <Tabs>
                <TabList>
                    {/* START LEFT MENU CONTENT */}
                    <div className="leftpart">
                        <div className="leftpart_inner">
                            <div className="logo">
                                <Link className="navbar-brand" to="/">
                                    <img src="/assets/img/logo/dark.png" alt="brand"/>
                                </Link>
                            </div>
                            {/* END LOGO */}

                            <div className="menu">
                                <ul>
                                    <Tab>
                                        <img
                                            className="svg"
                                            src="/assets/img/svg/home-run.svg"
                                            alt="homerun"
                                        />
                                        <span className="menu_content">Home</span>
                                    </Tab>
                                    <Tab>
                                        <img
                                            className="svg"
                                            src="/assets/img/svg/avatar.svg"
                                            alt="Home"
                                        />
                                        <span className="menu_content">About me</span>
                                    </Tab>
                                    <Tab>
                                        <img
                                            className="svg"
                                            src="/assets/img/svg/avatar.svg"
                                            alt="Software developer"
                                        />
                                        <span className="menu_content">Developer</span>
                                    </Tab>
                                    <Tab>
                                        <img
                                            className="svg"
                                            src="/assets/img/svg/avatar.svg"
                                            alt="Trainer"
                                        />
                                        <span className="menu_content"> Contact</span>
                                    </Tab>
                                </ul>
                            </div>
                            {/* END MENU */}

                            <CopyRight/>
                            {/* END COPYRIGHT */}
                        </div>
                    </div>
                    {/* END LEFT MENU CONTENT */}
                </TabList>
                {/* END SIDEBAR TABLIST */}

                {/* START RIGHT PART CONTENT */}
                <div className="rightpart">
                    <div className="rightpart_in">
                        <div className="tokyo_tm_section">
                            <TabPanel>
                                <div data-aos="fade-right" data-aos-duration="1200">
                                    <Home/>
                                </div>
                            </TabPanel>
                            {/* END HOME MENU TAB CONTENT */}

                            <TabPanel>
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                    data-aos-delay="100"
                                >
                                    <About/>
                                </div>
                            </TabPanel>
                            {/* END ABOUT MENU TAB CONTENT */}
                            <TabPanel>
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                    data-aos-delay="200"
                                >
                                    <Work/>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                    data-aos-delay="200"
                                >
                                    <Contact/>
                                </div>
                            </TabPanel>
                            {/* END CONTACT MENU TAB CONTENT */}
                        </div>
                    </div>
                </div>
                {/* END RIGHT PART CONTENT */}
            </Tabs>
            {/* END TABS */}
        </>
    );
};

export default Website;
